import { type FC, type ReactNode } from 'react';
import classNames from 'classnames';

import stylesheet from './GridCol.scss';
type additionalStyles = typeof stylesheet & { [className: string]: string };
const styles: additionalStyles = stylesheet;

type GridColVerticalAlign = 'top' | 'bottom' | 'center' | 'stretch';
type GridColOrder = 'first' | 'last';

export type ColOptions = {
    verticalAlign?: GridColVerticalAlign;
    order?: GridColOrder;
    offset?: number;
};

type GridColProps = {
    children?: ReactNode;
    desktop?: number;
    desktopOptions?: ColOptions;
    mobile?: number;
    mobileOptions?: ColOptions;
    tabletLandscape?: number;
    tabletLandscapeOptions?: ColOptions;
    tabletPortrait?: number;
    tabletPortraitOptions?: ColOptions;
    dataTn?: string;
};

function capitalize(value?: GridColVerticalAlign | GridColOrder): string {
    if (!value) {
        return '';
    }
    const [firstLetter] = value;
    const restOfLetters = value.substring(1);
    return `${firstLetter.toUpperCase()}${restOfLetters.toLowerCase()}`;
}

const GRID_COLUMNS = 12;
function isGridValueValid(number: number | string): boolean {
    const isNumber = typeof number === 'number';
    return isNumber && number > 0 && number <= GRID_COLUMNS;
}

const DEVICE_TYPE_MOBILE = 'mobile';
const DEVICE_TYPE_TABLET_PORTRAIT = 'tabletPortrait';
const DEVICE_TYPE_TABLET_LANDSCAPE = 'tabletLandscape';
const DEVICE_TYPE_DESKTOP = 'desktop';

const deviceType = {
    mobile: 'colMobile',
    tabletPortrait: 'colTabletPortrait',
    tabletLandscape: 'colTabletLandscape',
    desktop: 'colDesktop',
};

const getDeviceTypeClass = (device: string): string => {
    switch (device) {
        case DEVICE_TYPE_MOBILE:
            return deviceType.mobile;
        case DEVICE_TYPE_TABLET_PORTRAIT:
            return deviceType.tabletPortrait;
        case DEVICE_TYPE_TABLET_LANDSCAPE:
            return deviceType.tabletLandscape;
        case DEVICE_TYPE_DESKTOP:
        default:
            return deviceType.desktop;
    }
};

export const GridCol: FC<GridColProps> = props => {
    const {
        children,
        desktop = '',
        desktopOptions = {},
        mobile = '',
        mobileOptions = {},
        tabletPortrait = '',
        tabletPortraitOptions = {},
        tabletLandscape = '',
        tabletLandscapeOptions = {},
        dataTn,
    } = props;

    const hasDeviceProp = desktop || mobile || tabletPortrait || tabletLandscape;
    const devices = [
        {
            device: DEVICE_TYPE_MOBILE,
            col: mobile,
            options: mobileOptions,
        },
        {
            device: DEVICE_TYPE_TABLET_PORTRAIT,
            col: tabletPortrait,
            options: tabletPortraitOptions,
        },
        {
            device: DEVICE_TYPE_TABLET_LANDSCAPE,
            col: tabletLandscape,
            options: tabletLandscapeOptions,
        },
        {
            device: DEVICE_TYPE_DESKTOP,
            col: desktop,
            options: desktopOptions,
        },
    ];

    const deviceCoreClasses = devices.map(({ device, col }) => {
        if (col && !isGridValueValid(col)) {
            // eslint-disable-next-line no-console
            console.warn(
                `dibs-elements | GridCol | Type Error: You have passed in "${col}" for prop "mobile", "desktop", "tabletPortrait" and "tabletLandscape". Only number between 0 and 12 is allowed. 🙈 moriko`
            );
        }

        const deviceTypeClass = getDeviceTypeClass(device); // deviceType[`${device}`];
        return styles[`${deviceTypeClass}${col}`];
    });
    const deviceOptionClasses = devices.map(({ device, options }) => {
        const { offset = '', order, verticalAlign } = options;
        const devicelCol = getDeviceTypeClass(device); // deviceType[`${device}`];
        const classes = [];
        if (offset) {
            if (!isGridValueValid(offset)) {
                // eslint-disable-next-line no-console
                console.warn(
                    `dibs-elements | GridCol | Type Error: You have passed in "${offset}" for prop "offset". Only number between 0 and 12 is allowed. 🙈 moriko`
                );
            }
            classes.push(styles[`${devicelCol}Offset${offset}`]);
        }
        if (order) {
            classes.push(styles[`${devicelCol}${capitalize(order)}`]);
        }
        if (verticalAlign) {
            classes.push(styles[`${devicelCol}${capitalize(verticalAlign)}`]);
        }
        return classes.join(' ');
    });

    const colClassNames = classNames(styles.col, deviceCoreClasses, deviceOptionClasses, {
        // fallback when mobile or tablet values are not provided
        [styles.colMobile12]: !mobile && hasDeviceProp,
        // eg. "colTabletPortrait6 colDesktop6" will be added if tabletPortrait or tabletLandscape
        // is not provided but the desktop value exist. This is required to maintain the grid in
        // tablet and desktop if only the desktop value was provided
        [styles[`${deviceType.tabletPortrait}${desktop}`]]:
            !tabletPortrait && !tabletLandscape && desktop,
    });

    return (
        <div className={colClassNames} data-tn={dataTn}>
            {children}
        </div>
    );
};
